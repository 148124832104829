@import "colors.scss";
@import "utilities.scss";
@import "common.scss";

*,
*::before,
*::after {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html {
	font-family: sans-serif;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: transparent;
	margin: 0;
	padding: 0;
	width: 100%;
	min-height: 100%;
	height: 100%;
	font-size: 118.8%; /*19px*/
}

body {
	font-family: "Futura", sans-serif;
	color: $textColor;
	line-height: 1.6;
	-webkit-font-smoothing: antialiased;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
	position: relative;
	text-rendering: optimizelegibility;
	-moz-osx-font-smoothing: grayscale;
	-moz-font-feature-settings: "liga";
	min-height: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	background-color: $lightGray;
}

ul {
	list-style-type: none;
}

a {
	text-decoration: none;
	color: $blue;
	cursor: pointer;
}

img {
	width: 100%;
}

p {
	margin-top: 0;
	margin-bottom: 1.5rem;
}

/*********************************************************
    ASSETS - Font Sizes Calculated 
	Using https://type-scale.com/ (1.067 Minor Second)
*********************************************************/
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	font-family: var(--headline);
	font-weight: 800;
	line-height: 1.3;
	margin-top: 0;
	margin-bottom: 1.5rem;
	letter-spacing: -1px;
}
h1,
h1,
.h1 {
	font-size: 1.802rem;
}
h2,
.h2 {
	font-size: 1.602rem;
}
h3,
.h3 {
	font-size: 1.424rem;
}
h4,
.h4 {
	font-size: 1.266rem;
}
h5,
.h5 {
	font-size: 1.125rem;
}

table {
	font-family: Arial, Helvetica, sans-serif;
	border-collapse: collapse;
	width: 100%;
	text-align: center;
	margin-bottom: 2rem !important;

	td,
	th {
		border: 1px solid #ddd;
		padding: 8px;
	}

	tr:nth-child(even) {
		background-color: #f2f2f2;
	}

	th {
		padding-top: 12px;
		padding-bottom: 12px;
		text-align: center;
	}
}
