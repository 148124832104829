@import "../../scss/colors";

.article-card {
	border: 1px solid #b9c8d4;
	border-radius: 5px;
	box-shadow: none;
	margin-bottom: 1rem;
	padding: 1rem;
}

.article-card a {
	color: $blue;
	text-decoration: underline;
}
