@import "../../scss/colors";

.local-authority {
	display: flex;
	align-items: flex-start;
}

.local-authority__details {
	flex: 1;
}

@media (max-width: 500px) {
	.local-authority {
		flex-direction: column;
		flex-wrap: wrap;
		gap: 2rem;
		overflow: hidden;
	}
}
