@import "../../scss/colors";

.detail-scores__area__heading {
	font-weight: bold;
	color: $primaryColor;
}

.detail-scores__area {
	margin-bottom: 2rem;
}
