@import "../../scss/colors";

.search-results {
	display: flex;
	flex-direction: column;
}

.search-results img {
	width: 250px;
}

.search-results .card {
	cursor: pointer;
}

.search-results .card h2 {
	font-size: 1.75rem;
}

.search-results .btn {
	width: 50%;
	justify-self: center;
	margin: auto;
	text-align: center;
	margin-top: 20px;
	font-size: 1rem;
	margin-bottom: 2rem;
}

.panel .search-results a {
	color: $textColor;
	text-decoration: none;
}

@media (min-width: 1024px) {
	.search-results .grid {
		grid-template-columns: 80%;
	}
}
