@import "../scss/colors";

.main-content {
	flex: 1;
	margin-top: 2rem !important;
}

.sidebar {
	flex: 0.2;
	background-color: $white;
	margin-top: 2rem !important;
}

.panel a {
	text-decoration: underline;
	color: $blue;
}

.article {
	background-color: $white;
	border-top: 4px solid $primaryColor;
	padding: 3rem 3rem 1rem 3rem;

	ul {
		list-style-type: square;
		margin-bottom: 1.5rem;
		margin-top: 1.5rem;
		margin-left: 1rem;
	}

	li {
		margin-bottom: 1rem;
	}
}

.floating-card {
	background-color: #fff;
	color: $textColor;
	border-radius: 10px;
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
	padding: 20px;
	margin: 10px;
}

.card {
	background-color: #fff;
	color: $textColor;
	border: 1px solid #b9c8d4;
	border-radius: 5px;
	box-shadow: none;
	margin-bottom: 1rem;
	padding: 1rem;
}

@media (max-width: 500px) {
	.article {
		padding: 1rem;
		margin-top: 0;
	}
}

@media (min-width: 1024px) {
	.panel .grid {
		grid-template-columns: repeat(3, 33.3%);
	}

	.panel .grid-2 {
		grid-template-columns: repeat(2, 50%);
	}
}
