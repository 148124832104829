@import "colors.scss";

.container {
	max-width: 1200px;
	margin: 0 auto;
	overflow: auto;
	padding: 0 40px;
}

.form-control {
	margin: 30px 0;
}

/* Backgrounds and colored buttons */
.bg-primary {
	background-color: $primaryColor;
	color: #fff;
}

.bg-secondary {
	background-color: $secondaryColor;
	color: #fff;
}

.bg-dark {
	background-color: $darkColor;
	color: #fff;
}

.bg-light {
	background-color: $lightColor;
	color: #333;
}

.bg-primary a,
.bg-secondary a,
.bg-dark a {
	color: #fff;
}

/* Text colors */
.text-primary {
	color: $primaryColor;
}

.text-secondary {
	color: $secondaryColor;
}

.text-dark {
	color: $darkColor;
}

.text-light {
	color: $lightColor;
}

.text-bold {
	font-weight: bold;
}

.text-italic {
	font-style: italic;
}

.text-underline {
	text-decoration: underline;
}

.text-small {
	font-size: 0.8rem;
}

.text-center {
	text-align: center;
}

.img-fluid {
	max-width: 100%;
	height: auto;
}
.min-height {
	min-height: 1000px;
}
.container-800 {
	max-width: 800px;
	margin: auto;
}
.w-100 {
	width: 100%;
}

.p-0 {
	padding: 0rem !important;
}
.p-1 {
	padding: 0.25rem !important;
}
.p-2 {
	padding: 0.5rem !important;
}
.p-3 {
	padding: 1rem !important;
}
.p-4 {
	padding: 2rem !important;
}
.p-5 {
	padding: 3rem !important;
}

.px-0 {
	padding-left: 0rem !important;
	padding-right: 0rem !important;
}
.px-1 {
	padding-left: 0.25rem !important;
	padding-right: 0.25rem !important;
}
.px-2 {
	padding-left: 0.5rem !important;
	padding-right: 0.5rem !important;
}
.px-3 {
	padding-left: 1rem !important;
	padding-right: 1rem !important;
}
.px-4 {
	padding-left: 2rem !important;
	padding-right: 2rem !important;
}
.px-5 {
	padding-left: 3rem !important;
	padding-right: 3rem !important;
}

.py-0 {
	padding-top: 0rem !important;
	padding-bottom: 0rem !important;
}
.py-1 {
	padding-top: 0.25rem !important;
	padding-bottom: 0.25rem !important;
}
.py-2 {
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;
}
.py-3 {
	padding-top: 1rem !important;
	padding-bottom: 1rem !important;
}
.py-4 {
	padding-top: 2rem !important;
	padding-bottom: 2rem !important;
}
.py-5 {
	padding-top: 3rem !important;
	padding-bottom: 3rem !important;
}

.pb-0 {
	padding-bottom: 0rem !important;
}
.pb-1 {
	padding-bottom: 0.25rem !important;
}
.pb-2 {
	padding-bottom: 0.5rem !important;
}
.pb-3 {
	padding-bottom: 1rem !important;
}
.pb-4 {
	padding-bottom: 2rem !important;
}
.pb-5 {
	padding-bottom: 3rem !important;
}

.pt-0 {
	padding-top: 0rem !important;
}
.pt-1 {
	padding-top: 0.25rem !important;
}
.pt-2 {
	padding-top: 0.5rem !important;
}
.pt-3 {
	padding-top: 1rem !important;
}
.pt-4 {
	padding-top: 2rem !important;
}
.pt-5 {
	padding-top: 3rem !important;
}

.m-0 {
	margin: 0rem !important;
}
.m-1 {
	margin: 0.25rem !important;
}
.m-2 {
	margin: 0.5rem !important;
}
.m-3 {
	margin: 1rem !important;
}
.m-4 {
	margin: 2rem !important;
}
.m-5 {
	margin: 3rem !important;
}

.mx-0 {
	margin-left: 0rem !important;
	margin-right: 0rem !important;
}
.mx-1 {
	margin-left: 0.25rem !important;
	margin-right: 0.25rem !important;
}
.mx-2 {
	margin-left: 0.5rem !important;
	margin-right: 0.5rem !important;
}
.mx-3 {
	margin-left: 1rem !important;
	margin-right: 1rem !important;
}
.mx-4 {
	margin-left: 2rem !important;
	margin-right: 2rem !important;
}
.mx-5 {
	margin-left: 3rem !important;
	margin-right: 3rem !important;
}

.my-0 {
	margin-top: 0rem !important;
	margin-bottom: 0rem !important;
}
.my-1 {
	margin-top: 0.25rem !important;
	margin-bottom: 0.25rem !important;
}
.my-2 {
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}
.my-3 {
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
}
.my-4 {
	margin-top: 2rem !important;
	margin-bottom: 2rem !important;
}
.my-5 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important;
}

.mb-0 {
	margin-bottom: 0rem !important;
}
.mb-1 {
	margin-bottom: 0.25rem !important;
}
.mb-2 {
	margin-bottom: 0.5rem !important;
}
.mb-3 {
	margin-bottom: 1rem !important;
}
.mb-4 {
	margin-bottom: 2rem !important;
}
.mb-5 {
	margin-bottom: 3rem !important;
}

.mt-0 {
	margin-top: 0rem !important;
}
.mt-1 {
	margin-top: 0.25rem !important;
}
.mt-2 {
	margin-top: 0.5rem !important;
}
.mt-3 {
	margin-top: 1rem !important;
}
.mt-4 {
	margin-top: 2rem !important;
}
.mt-5 {
	margin-top: 3rem !important;
}

.flex {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.grid {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 1rem;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.active {
	display: block;
}

.responsive-table {
	overflow-x: auto;
}

@media (max-width: 500px) {
	.flex {
		flex-direction: column;
	}

	.container {
		padding: 0;
	}
}
