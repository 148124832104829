@import "../../scss/colors";

.establishment-detail-section {
	background-color: $white;
	border-top: 4px solid $primaryColor;
	padding: 3rem;
	margin-bottom: 1.5rem;
	overflow: hidden;
}

.establishment-detail-section__info {
	display: flex;
	gap: 4rem;
	margin-top: 2rem !important;
	margin-bottom: 2rem !important;
}

.establishment-detail-section__info__rating {
	flex: 1;
	border-right: 2px solid $lightGray;
}

.establishment-detail-section__info__misc {
	flex: 1;
}

.rating-image {
	width: 300px;
	margin-bottom: 1rem;
}

@media (max-width: 500px) {
	.establishment-detail-section {
		padding: 1rem;
		margin-top: 0;
	}

	.establishment-detail-section__info {
		flex-direction: column;
		gap: 2rem;
	}

	.rating-image {
		width: 200px;
	}

	.establishment-detail-section__info__rating {
		border-right: none;
		border-bottom: 2px solid $lightGray;
		padding-bottom: 2rem;
	}
}
