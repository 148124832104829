@import "../../scss/colors";

.total-score {
	font-weight: bold;
}

.perfect-score {
	color: $successColor;
	font-weight: bold;
	font-size: 0.68rem;
	padding-top: 20px;

	&__icon {
		&--right {
			color: $successColor;
			font-size: 1rem;
			margin-left: 10px;
		}

		&--left {
			color: $successColor;
			font-size: 1rem;
			margin-right: 10px;
		}
	}
}

@media (min-width: 500px) {
	.perfect-score {
		font-size: 1rem;
	}
}
