@import "../../scss/utilities";

.navbar {
	background-color: $darkColor;
	color: #fff;
	height: 150px;
	padding: 20px 0px;
}

.navbar ul {
	display: flex;
}

.navbar a:hover {
	border-bottom: 3px #fff solid;
}

.navbar .flex {
	justify-content: space-between;
}

.navbar .flex {
	flex-direction: column;
}

.navbar ul {
	padding: 8px;
	background-color: rgba(0, 0, 0, 0.1);
}

.navbar__links a {
	font-size: 0.8rem;
	color: #fff;
	padding: 3px;
	margin: 0 5px;
}

h1.navbar__logo {
	margin: 0;
}

.navbar__logo a {
	color: #fff;
}

.navbar__logo a:hover {
	border: none;
}

.navbar__new-feature {
	color: $primaryColor;
}

@media (min-width: 1024px) {
	.navbar {
		height: 110px;
	}

	.navbar .flex {
		flex-direction: row;
	}

	.navbar ul {
		background-color: $darkColor;
	}

	.navbar__links a {
		color: #fff;
		font-size: 1.1rem;
		padding: 10px;
		margin: 0 15px;
	}
}
