@import "../../scss/colors";

.address {
	margin-bottom: 1rem;
}

.address__heading {
	font-weight: bold;
	color: $primaryColor;
}
