.center {
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
	justify-content: center;
	align-items: center;
	padding: 10px;

	&-fullscreen {
		height: 100vh;
	}
}

.no-data {
	font-size: 2rem;
}
