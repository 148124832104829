.loader {
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
	justify-content: center;
	align-items: center;

	&-fullscreen {
		height: 100vh;
	}
}

@keyframes spinner-border {
	to {
		transform: rotate(1turn);
	}
}

.spinner-border {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: text-bottom;
	border: 0.25em solid;
	border-right: 0.25em solid transparent;
	border-radius: 50%;
	animation: spinner-border 0.75s linear infinite;
	margin-bottom: 100px;
}

.spinner-border-sm {
	width: 1rem;
	height: 1rem;
	border-width: 0.2em;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	overflow: visible;
	clip: auto;
	white-space: normal;
}
