$textColor: #333;
$primaryColor: #f39;
$secondaryColor: #ccc;
$darkColor: #393e42;
$lightColor: #f4f4f4;
$successColor: #32cd32;
$errorColor: #d9534f;
$blue: #158ae5;
$darkerBlue: #0099e6;
$black: #000000;
$lightGray: #f1f4f6;
$white: #ffffff;
