@import "../../scss/colors";

.btn {
	display: inline-block;
	padding: 10px 20px;
	cursor: pointer;
	background: $primaryColor;
	color: #fff;
	border: none;
	border-radius: 5px;

	&--outline {
		background-color: transparent;
		border: 1px #fff solid;
	}

	&--primary {
		background-color: $primaryColor;
		color: #fff;
	}

	&--secondary {
		background-color: $secondaryColor;
		color: #fff;
	}

	&--dark {
		background-color: $darkColor;
		color: #fff;
	}

	&--light {
		background-color: $lightColor;
		color: #333;
	}
}

.btn--primary a,
.btn--secondary a,
.btn--dark a {
	color: #fff;
}

.btn:hover {
	transition: 0.2s ease;
	transform: translateY(5px);
	box-shadow: none !important;
}
