input[type="text"] {
	border: 0;
	border-bottom: 1px solid #b4becb;
	width: 100%;
	padding: 3px;
	font-size: 16px;
}

input:focus {
	outline: none;
}
