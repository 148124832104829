@import "../../scss/colors";

.inspection-date {
	margin-bottom: 1rem;
}

.inspection-date__heading {
	font-weight: bold;
	color: $primaryColor;
}
