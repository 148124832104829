@import "../../scss/colors";

.sources {
	margin-bottom: 1rem;
}

.sources__heading {
	font-weight: bold;
	color: $primaryColor;
}
