@import "../../scss/utilities";

.home-panel {
	height: auto;
	background-color: $darkColor;
	color: #fff;
	position: relative;
}

.home-panel::before,
.home-panel::after {
	content: "";
	position: absolute;
	height: 100px;
	bottom: -70px;
	right: 0;
	left: 0;
	background: $lightGray;
	transform: skewY(-3deg);
	-webkit-transform: skewY(-3deg);
	-moz-transform: skewY(-3deg);
	-ms-transform: skewY(-3deg);
}

.home-panel .grid {
	overflow: visible;
}

.home-panel h1 {
	font-size: 2.2rem;
	letter-spacing: -3px;
}

.home-panel__text {
	text-align: center;
	margin-top: 1rem;
	padding: 0.5rem;
}

.home-panel__form {
	height: 300px;
	width: 370px;
	padding: 40px !important;
	z-index: 100;
	position: relative;
	top: 0px;
}

@media (min-width: 500px) {
	.home-panel__form {
		justify-self: center;
		margin: auto;
		width: 400px;
	}
}

@media (min-width: 1024px) {
	.home-panel {
		height: 400px;
	}

	.home-panel .grid {
		grid-template-columns: 55% auto;
		gap: 80px;
	}

	.home-panel__text {
		text-align: left;
		margin-top: 40px;
	}

	.home-panel__form {
		position: relative;
		top: 45px;
	}
}
