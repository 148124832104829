@media (max-width: 500px) {
	.footer .container {
		padding: 0 40px;
	}
}

@media (min-width: 1024px) {
	.footer .grid {
		grid-template-columns: 2fr 1fr 1fr;
	}
}

.footer-legal {
	background-color: #333;
}

.footer .grid {
	align-items: flex-start;
}
