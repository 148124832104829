@import "../../scss/colors";

.last-updated {
	margin-bottom: 1rem;
}

.last-updated__heading {
	font-weight: bold;
	color: $primaryColor;
}
