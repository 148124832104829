@import "../../scss/colors";

.rating-details__text {
	margin-bottom: 1rem;
}

.detail-main__rating-text a {
	color: $blue;
	text-decoration: underline;
}
